import React from 'react'

const Pricing = () => {
  return (
    <div className='pricing' id='pricing'>
      <section>
        <header><h1>Pricing</h1><p>Simple and Affordable pricing that grows with you.</p></header>
        <article>
            <ul className='eachpriceCon'>
                <li className='eachprice'>
                    <main><h3><img alt='' src=''/>Free</h3><p>Great for <span>testing</span></p></main>
                    <main><h4>0SOL/month</h4></main>
                    <main>
                        <ul>
                            <li>10 free wallets</li>
                            <li>20 notifications/min</li>
                            <li>500 notifications/day</li>
                            <li>Community support</li>
                            <a href='https://t.me/Godeye_wallet_trackerBot'> <button>
            Start Godeye 
        </button></a>
                        </ul>
                    </main>
                </li>
                <li className='eachprice'>
                    <main><h3><img alt='' src='/images/phoenix.png'/>Phoenix</h3><p>Great for <span>casual users</span></p></main>
                    <main><h4>0.2SOL/month</h4></main>
                    <main>
                        <ul>
                            <li>100 wallets</li>
                            <li>60 notifications/min</li>
                            <li>5000 notifications/day</li>
                            <li>Advanced features</li>
                            <li>Chat support</li>
                              <a href='https://t.me/Godeye_wallet_trackerBot'> <button>
            Start Godeye 
        </button></a>
                        </ul>
                    </main>
                </li>
                <li className='eachprice'>
                    <main><h3><img alt='' src='/images/valkyrie.png'/>Valkyrie</h3><p>Ideal for <span>trench warriors</span> </p></main>
                    <main><h4>0.3SOL/month</h4></main>
                    <main>
                        <ul>
                            <li>200 wallets</li>
                            <li>80 notifications/min</li>
                            <li>10000 notifications/day</li>
                            <li>Advanced features</li>
                            <li>Chat support</li>
                              <a href='https://t.me/Godeye_wallet_trackerBot'> <button>
            Start Godeye 
        </button></a>
                        </ul>
                    </main>
                </li>
                <li className='eachprice'>
                    <main><h3><img alt='' src='/images/odin.png'/>Odin</h3><p>Ideal for <span>titans</span></p></main>
                    <main><h4>0.5SOL/month</h4></main>
                    <main>
                        <ul>
                            <li>400 wallets</li>
                            <li>100 notifications/min</li>
                            <li>∞ notifications/day</li>
                            <li>Advanced features</li>
                            <li>Chat support</li>
                              <a href='https://t.me/Godeye_wallet_trackerBot'> <button>
            Start Godeye 
        </button></a>
                        </ul>
                    </main>
                </li>
                <li className='eachprice'>
                    <main><h3><img alt='' src='/images/speed.png'/>Zeus</h3><p>Perfect for <span>elite</span></p></main>
                    <main><h4>1SOL/month</h4></main>
                    <main>
                        <ul>
                            <li>600 wallets</li>
                            <li>120 notifications/min</li>
                            <li>∞ notifications/day</li>
                            <li>Advanced features</li>
                            <li>Priority Chat support</li>
                              <a href='https://t.me/Godeye_wallet_trackerBot'> <button>
            Start Godeye 
        </button></a>
                        </ul>
                    </main>
                </li>
            </ul>
        </article>
      </section>
    </div>
  )
}

export default Pricing
